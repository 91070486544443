import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { m } from 'framer-motion';
import { Flex, HistoryList, SelectOption } from '@components';
import { useOrders } from '@services/poms';
import { changePlacesVariants } from '@utils/motions';
import { useCustomer } from '@services/shopify';
import { getShopifyIdFromGID } from '@utils/index';
import styles from './History.module.scss';

const headerAndAccountTabsHeight = 144;

const History = ({ forwardedRef }) => {
	const { data: customer } = useCustomer();
	const userId = getShopifyIdFromGID(customer?.id);
	const { push } = useRouter();
	const [page, setPage] = useState(1);
	const [range, setRange] = useState(null);
	const { data: orderData, isLoading } = useOrders({ userId, page, range });
	const [pagesLength, setPagesLength] = useState(orderData?.pagination.pages ?? 1);

	const parentRef = useRef<HTMLDivElement>(null);
	const historyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!isLoading && forwardedRef.current && historyRef.current) {
			const parentHeight = forwardedRef.current.clientHeight;
			const childHeight = historyRef.current.clientHeight;
			if (parentHeight !== childHeight) {
				forwardedRef.current.style.height = childHeight + headerAndAccountTabsHeight + 'px';
			}
		}
	}, [forwardedRef, isLoading, historyRef]);

	const handleItemClick = (id: string, el?: EventTarget & HTMLDivElement) => {
		push(`/account/orders/${id}`);
	};

	const handleSelect = useCallback((selection: SelectOption) => {
		setRange(selection.value);
	}, []);

	const handlePagination = useCallback(selection => setPage(selection), []);

	useEffect(() => {
		if (!orderData) return;
		if (!pagesLength || pagesLength !== orderData.pagination.pages) return setPagesLength(orderData.pagination.pages);
	}, [orderData, pagesLength, setPagesLength]);

	return (
		<Flex className={styles['history']} ref={parentRef}>
			<m.div
				style={{ position: 'absolute' }}
				initial={'visible'}
				animate={'visible'}
				variants={changePlacesVariants}
				className={styles['history__animation-wrapper']}
				ref={historyRef}
			>
				<HistoryList
					containerClass={styles['history__list-container']}
					handleItemClick={handleItemClick}
					handlePagination={handlePagination}
					handleSelect={handleSelect}
					isLoading={isLoading}
					orderData={orderData}
					pagesLength={pagesLength}
				/>
			</m.div>
		</Flex>
	);
};

export default History;
